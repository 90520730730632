import React from "react";
import { Generate } from "../Generate/Generate";
import DMNICON2 from "./DMNICON-2.png";
import "./style.css";
import { Button } from "antd";
import { Navbar } from "../Navbar";

export const LandingPage = (): JSX.Element => {
  return (
    <div className="landing-page">
      <div className="overlap-wrapper">
        <div className="overlap"></div>
        <div className="overlap-group">
          <img className="DMNICON" alt="Dmnicon" src={DMNICON2} />
        </div>
      </div>
      <Navbar />
    </div>
  );
};
