import React, { useState, useEffect, CSSProperties } from "react";
import { Menu, Button, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import DMNICON2 from "../components/LandingPage/DMNICON-2.png";

export const Navbar = () => {
  const [visible, setVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => window.innerWidth <= 768;
    setIsMobile(checkMobile());

    const handleResize = () => {
      setIsMobile(checkMobile());
      if (!checkMobile()) {
        setVisible(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navbarStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    padding: "1rem 2rem",
    color: "#ffffff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 100,
    background: "transparent", // Dark background to match the image
    height: "80px", // Fixed height to match the image
  };

  const logoStyle: CSSProperties = {
    width: "80px",
    height: "80px",
    marginRight: "2rem", // Reduced space after logo
    marginLeft: "2rem", // Reduced space after logo
  };

  const menuContainerStyle: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flex: 1,
  };

  const desktopMenuStyle: CSSProperties = {
    display: isMobile ? "none" : "flex",
    alignItems: "center",
    marginLeft: "0", // Remove default margin
  };

  const menuStyle: CSSProperties = {
    background: "transparent",
    color: "#ffffff",
    borderBottom: "none",
    display: "flex",
  };

  const menuItemStyle: CSSProperties = {
    fontSize: "30px",
    fontWeight: 900,
    color: "#ffffff",
    padding: "0 1.5rem", // Adjusted padding between menu items
  };

  const signinButtonStyle: CSSProperties = {
    backgroundColor: "#7800ff",
    color: "#ffffff",
    fontWeight: "bold",
    border: "none",
    borderRadius: "8px",
    marginLeft: "auto", // Push sign in to the right
    height: "40px",
    padding: "0 1.5rem",
  };

  const mobileMenuIconStyle: CSSProperties = {
    display: isMobile ? "block" : "none",
    marginLeft: "auto",
  };

  const menuIconButtonStyle: CSSProperties = {
    fontSize: "24px",
    color: "#ffffff",
  };

  const drawerMenuStyle: CSSProperties = {
    padding: "1rem",
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const menuItems = [
    { key: "product", label: "Product" },
    { key: "features", label: "Features" },
    { key: "pricing", label: "Pricing" },
    { key: "community", label: "Community" },
  ];

  return (
    <nav style={navbarStyle}>
      {/* Logo */}
      <img src={DMNICON2} alt="Logo" style={logoStyle} />

      <div style={menuContainerStyle}>
        {/* Desktop Menu */}
        <div style={desktopMenuStyle}>
          <Menu mode="horizontal" style={menuStyle}>
            {menuItems.map((item) => (
              <Menu.Item key={item.key} style={menuItemStyle}>
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
          <Button style={signinButtonStyle}>Sign In</Button>
        </div>

        {/* Mobile Menu Icon */}
        <div style={mobileMenuIconStyle}>
          <Button
            type="text"
            icon={<MenuOutlined />}
            onClick={showDrawer}
            style={menuIconButtonStyle}
          />
        </div>
      </div>

      {/* Mobile Drawer */}
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        open={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="vertical" style={drawerMenuStyle}>
          {menuItems.map((item) => (
            <Menu.Item key={item.key} onClick={onClose}>
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ padding: "1rem" }}>
          <Button style={{ ...signinButtonStyle, width: "100%" }}>
            Sign In
          </Button>
        </div>
      </Drawer>
    </nav>
  );
};
